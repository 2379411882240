import React from 'react'
import { FormattedMessage, useIntl } from "react-intl";
import { COMPENSATE_SCREEN } from "../../constants";
import FormActionButton from '../shared/FormActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';


export default function Summary({ onBack, onNext, compensate}) {

    const intl = useIntl();

  return (<>

        <div className="heading fdc-form d-flex justify-content-center align-items-center uplers-icon"> 
            <img src="/uplers-icons/icon2.svg" /> 
            <h2 className="title">
                { intl.formatMessage({id:"Please review the information about your claim."})}
            </h2>
        </div>
        <div className="fdc-form gif-input">
            <div className="row">
            <div className='col-md-12'>
                    <div className='form-block'>
                        <h3><b><FormattedMessage id='Case Reference:' /></b> <span style={{color: '#198754'}}>{compensate?.caseId}</span></h3>   
                        <h3><b><FormattedMessage id="Disruption:"/></b> {compensate?.delayReason3}</h3>
                        {compensate?.bookingReference && <h3><b><FormattedMessage id="Booking Reference:"/></b> <span style={{color: '#198754'}}>{compensate?.bookingReference}</span></h3>}
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='form-block'>
                        <h3><b><FontAwesomeIcon icon={faPlane} className='pe-4'/><FormattedMessage id='Trip Overview'/></b></h3>
                        {!compensate.connectingFlight && <h3 className='title'>{compensate?.flightData?.departureAirport} ({compensate?.flightData?.departureAirportCode}) to {compensate?.flightData?.arrivalAirport} ({compensate?.flightData?.arrivalAirportCode})</h3>} 
                        {compensate.connectingFlight && <> {compensate.connectingFlights.map((flight) =>(
                            <h3 className='title'>{flight.departureAirport?.label.split(",")[0]} ({flight.departureAirport?.value}) to {flight.destinationAirport?.label.split(",")[0]} ({flight.destinationAirport?.value})</h3>
                        ))}
                         </>}
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='form-block'>
                    <h3><b><FontAwesomeIcon icon={faUser} className='pe-4' /><FormattedMessage id="Your Details"/></b></h3>
                    <h3 className='title'>Name: {compensate?.title} {compensate?.firstName} {compensate?.surname}</h3>
                    <h3 className='title'>Address: {compensate?.address1} {compensate?.addressLine2} {compensate?.city}</h3>
                    <h3 className='title'>Phone: {compensate?.mobileNumber}</h3>
                    <h3 className='title'>Email: {compensate?.emailAddress}</h3>
                    </div>
                </div>
                {compensate.clients && compensate.clients?.length  ?
                    <div className='col-md-12'>
                        <div className='form-block'>
                        <h3><b><FontAwesomeIcon icon={faUsers} style={{color: '#198754'}} className='pe-4' /><FormattedMessage id="Passengers"/></b></h3>
                            {compensate.clients.map((client) => (
                                <h3 className='title' key={client.number}>Passenger {client.number} Name: {client.firstname} {client.lastname}</h3>
                            ))}
                        </div>
                    </div> : <div></div>}
            </div>
        </div>
        <div className="col-md-6 text-center mx-auto">
        <br/>
            <img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="SUMMARY encouragement text" values={{CustomerService: <a href="mailto:info@airtravelclaim.com" target="_blank" rel="noopener noreferrer">Customer Service</a>}}/></b>
            <FormActionButton
                onNext={() => {      
                    onNext({
                        
                    }, (COMPENSATE_SCREEN.SUMMARY + 1))                    
                }}
            />
        </div>
  </>)
}
