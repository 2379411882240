import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, GET_FLIGHT_ENDPOINT } from "../../../constants";
import FormActionButton from "../../shared/FormActionButton";
import DatePicker from "../../shared/DatePicker";
import axios from "axios";
import moment from "moment";
import { useLocation } from "react-router-dom";
import ListRadioOptions from "../../shared/ListRadioOptions";
import Label from "../Label";
import Text from "../Text";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import Button_ from "../Button";


export default function DepartureDate({ compensate, onNext, onBack, isSubmitted, areDetailsSubmitted, setAreDetailsSubmitted,
        departure, destination, nextPage, previousPage, affectedFlightss, directFlight, affectedFlight, lastLeg, replacementFlight}) {
    const intl = useIntl();
    

    const search = useLocation().search;
    const marketingChannel = new URLSearchParams(search).get('utm_source') || new URLSearchParams(search).get('utm_medium');
    const marketingCampaignName = new URLSearchParams(search).get('utm_campaign');
    const marketingCreative = new URLSearchParams(search).get('utm_content');
    const marketingAudience = new URLSearchParams(search).get('utm_term');
    const caseReference = new URLSearchParams(search).get('r');
    const utmMedium = new URLSearchParams(search).get('utm_medium');
    const utmSource = new URLSearchParams(search).get('utm_source')
    const utmCampaign = new URLSearchParams(search).get('utm_campaign');
    const utmCampaignId = new URLSearchParams(search).get('utm_campaign_id');
    const utmDevice = new URLSearchParams(search).get('utm_device');
    const utmHeadline = new URLSearchParams(search).get('utm_headline');
    const utmTerm = new URLSearchParams(search).get('utm_term');
    const utmTermId = new URLSearchParams(search).get('utm_term_id');
    const utmAdsetId = new URLSearchParams(search).get('utm_adset_id');
    const utmGclid = new URLSearchParams(search).get('utm_gclid');
    const utmExtension = new URLSearchParams(search).get('utm_extension');
    const utmContent = new URLSearchParams(search).get('utm_content');


    const [departureDate, setDepartureDate] = useState(compensate.departureDate)
    const [isFlightsLoading, setFlightsLoading] = useState(false);
    const [flightsList, setFlightsList] = useState(compensate.flightsList ?? []);
    const [flightData, setFlightData] = useState(compensate.flightData ?? {});
    const [flightsRecords, setflightsRecords] = useState(compensate.flightsRecords ?? []);
    const [mobileNumber, setMobileNumber] = useState(compensate.mobileNumber ?? null)
    const [isSourceAffiliate, setIsSourceAffiliate] = useState(compensate.isSourceAffiliate)
    const [whatsappOptIn, setWhatsappOptIn] = useState(compensate.whatsappOptIn)
    const [affectedFlights, setAffectedFlights] = useState(compensate?.affectedFlights ?? null)
    const [connectingFlights, setConnectingFlights] = useState(compensate?.connectingFlights ?? null)
    const [departureDateError, setDeparturedateError] = useState(false);
    const [searchLoader, setsearchLoader] = useState(false);
    const [noFlightsFound, setNoFlightsFound] = useState(false)
    

    useEffect(() => {
        const currentUrl = new URL(window.location.href);
        if (currentUrl.searchParams.get('source')  === 'affiliate') {
            setIsSourceAffiliate(true)
        }
    }, [])

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

 
    const departureAirport = departure
    const destinationAirport = destination    

    
    const loadF = (e) => {
        setDepartureDate(e)
        setDeparturedateError(false)

    }

    console.log(noFlightsFound)

    const loadFlights = async (departureDate_,mobile) => {
        if (compensate?.departureAirport?.value && compensate?.destinationAirport?.value) {
            setFlightsLoading(true);
            setFlightsList([])
             
            const data = {
                "arrivalAirport": destinationAirport?.value,
                "departureAirport":  departureAirport?.value, 
                "departureDate": moment(departureDate_).format('YYYY-MM-DD'),
                "guid": compensate.sessionId,
                "userNumber": mobile ?? ""
            };

            isSubmitted(true)

            axios.post(
                GET_FLIGHT_ENDPOINT(),data)
                .then(async flights => {

                const AirlinesMap = {};
                // const allAirlines = await axios.get('https://flights.tanantech.co.uk/1.0/all_airlines');
                // allAirlines?.data?.data?.forEach(airline => AirlinesMap[airline.airlineName] = airline);


                const flightsList_ = flights?.data?.message?.data?.map(flight => {

                    let flightLabel = '';
                    try {
                        flightLabel = `${moment(flight.scheduledDepartureDate).format('HH:mm')} - ${moment(flight.scheduledArrivalDate).format('HH:mm')} ${flight.codeshare.marketingAirlineName ?? flight.operatingAirlineName}`


                        console.log(flightLabel)
                    } catch (e) {
                        console.log('format error', e)
                    }
                    return {
                        ...flight,
                        flightLabel,
                        airline: {
                            label: flight.codeshare.marketingAirlineName ?? flight.operatingAirlineName,
                            value: flight.operatingAirlineCode,

                        }
                    }
                }) ?? [];
              
             

                if (flightsList_.length === 0) {
                    await delay(3000)
                    setAreDetailsSubmitted(true)
                    isSubmitted(false)
                    setNoFlightsFound(true)
                }

                
                
                //const flightsss = flightsList_?.filter(o => o?.airline)?.map(data => ({ text: data.flightLabel, value: data.flightLabel }));
                const filteredCountries = flightsList_.map((o) => ({ text: o.flightLabel, value: o.flightLabel }));
                setFlightsLoading(false);
                 if(filteredCountries.length === flightsList_.length){
                    setFlightsList(filteredCountries);
                    setflightsRecords(flightsList_);
                    const flightsList = filteredCountries;
                    const flightsRecords = flightsList_;
                    await delay(3000)
                    setAreDetailsSubmitted(true)
                    isSubmitted(false)
                    if (departureDate && (!isSourceAffiliate || mobileNumber)) {
                         //!isFlightsLoading
                        onNext({
                            flightsList,
                            departureDate,
                            flightsRecords,
                            replacementFlightRecords: replacementFlight ? flightsRecords : compensate.replacementFlightRecords,
                            lastLegRecords: lastLeg ? flightsRecords : compensate.lastLegRecords, 
                            affectedFlightRecords: affectedFlight ? flightsRecords : compensate.affectedFlightRecords,
                            affectedFlightDate: affectedFlight ? departureDate_ : compensate.affectedFlightDate,
                            lastLegDate: lastLeg ? departureDate_ : compensate.lastLegDate,
                            replacementFlightDate: replacementFlight ? departureDate_: compensate.replacementFlightDate,
                            flightDay: departureDate?.split("-")[2],
                            flightMonth: departureDate?.split("-")[1],
                            flightYear: departureDate?.split("-")[0], 
                            flightData,
                            mobileNumber,
                            whatsappOptIn,
                            caseReference,
                            isSourceAffiliate,
                            marketingChannel,
                            marketingCampaignName,
                            marketingCreative,
                            marketingAudience,
                            utmMedium,
                            utmSource,
                            utmCampaign,
                            utmCampaignId,
                            utmDevice,
                            utmHeadline,
                            utmTerm,
                            utmTermId,
                            utmAdsetId,
                            utmGclid,
                            utmExtension,
                            utmContent,
                            affectedFlightss,
                            isMobileVerified: mobileNumber  === compensate.mobileNumber && compensate.isMobileVerified
                        }, (nextPage(flightsList_) ))
                    }
            
                 }
               
            }).catch(e => {
                setflightsRecords([])
                setFlightsList([])
                isSubmitted(false)  
            })
        }
    }



    return (
        <>
            <Popup open={noFlightsFound === true} className='undo-counter' onClose={() => setNoFlightsFound(false)} modal>
                {close => (
                    <div>
                        <img src="/uplers-icons/icon5.svg" style={{height: '100px'}} /> 
                        <br />
                        <br />
                        {intl.formatMessage({
                            id:"The flight you entered from X to Y on DATE has not been found."}, 
                        {   departure: departureAirport?.label.split(",")[1] ,
                            arrival: destinationAirport?.label.split(",")[1], 
                            date: moment(departureDate).format('DD/MM/YYYY')    
                        })}
                        <br />
                        <br />
                        {intl.formatMessage({id:  "Please double check the information you have entered by simply closing this message and clicking the back button at the bottom of the page."})}
                        <Button_
                            label={'OK'}
                            onClick={() => {setNoFlightsFound(false); close();}}
                            style={{backgroundColor: '#198754'}}
                        />
                    </div>
            ) }
            </Popup>

            <div className="heading fdc-form d-flex justify-content-center align-items-center uplers-icon">
                <img src="/uplers-icons/icon2.svg" /> 
                {directFlight && <h2 className="title"><FormattedMessage id="Let's check the details of your flight" /></h2>}
                {affectedFlight && <h2 className="title">
                    <FormattedMessage 
                        id="Let's check the details for the flight that caused the disruption"
                        defaultMessage="Let's check the details for the flight that <strong>caused</strong> the disruption"
                        values={{
                            strong: (chunks) => <strong>{chunks}</strong>
                        }}/></h2>}
                {lastLeg && <h2 className="title">
                    <FormattedMessage 
                        id="Let's check the flight details of your connecting flight" 
                        defaultMessage="Let's check the flight details of your <strong>connecting</strong> flight"
                        values={{
                            strong: (chunks) => <strong>{chunks}</strong>
                        }} />
                        </h2>}
                {replacementFlight && <h2 className="title">
                    <FormattedMessage 
                            id="Let's check your replacement flight details" 
                            defaultMessage="Let's check your <strong>replacement</strong> flight details"
                            values={{
                                strong: (chunks) => <strong>{chunks}</strong>
                            }} />
                </h2>}
            </div>
            <div className="fdc-form gif-input">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-block">
                            { replacementFlight
                                ? <Label label={intl.formatMessage({ id: "As the delay caused you to miss your flight, what was the scheduled departure date of the replacement flight from {departureAirport} to {destinationAirport}" }, { departureAirport: <b>{departureAirport?.label.split(",")[1]}</b>, destinationAirport: <b>{destinationAirport?.label.split(",")[1]}</b> })} />
                                : <Label 
                                    label={
                                        <FormattedMessage  
                                            id="What was your scheduled departure date for the flight from {departureAirport} to {destinationAirport}" 
                                            values={{
                                                departureAirport: <b>{departureAirport?.label.split(",")[1]}</b>, 
                                                destinationAirport: <b>{destinationAirport?.label.split(",")[1]}</b>
                                            }}
                                       />
                                        }
                                    
                                    />
                                      
                                            
                            }
                            <DatePicker
                                onChange={(formattedDate) => { loadF(formattedDate) }}
                                value={departureDate}
                                flightSearch
                                compensate={compensate}
                            />
                                   {departureDateError ?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please select the departure date"/>
                                    </span>
                                </div>
                                :<></>
                              }
                        </div>

                 
                    </div>

                    { !areDetailsSubmitted && isSourceAffiliate && <div className="col-md-12">
                            <div className="form-block">
                                <Text
                                    label={intl.formatMessage({ id: "Please provide your Mobile number" })}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                    value={mobileNumber}
                                    required={false}
                                    placeholder={''}
                                />
                                <ListRadioOptions 
                                     className={`d-inline-flex no-radius ps-0`}
                                     items={[
                                         { text: "Receive claim updates on WhatsApp", value: true },
                                     ]}
                                     onSelect={() => setWhatsappOptIn(!whatsappOptIn)}
                                     value={whatsappOptIn}
                                />                                
                            </div>
                        </div>}
                    
                   {/*!areDetailsSubmitted && <p className="sig-more-info"><FormattedMessage id="We will use your details to keep in touch with you about your claim and to send you service and marketing related messages. To opt out email compliance@airtravelclaim.com" /></p>*/}
                    
                    <div className="col-md-6 text-center mx-auto">
                        <FormActionButton
                            onNext={() => {
                                 
                                    if(departureDate === undefined || departureDate === ''){setDeparturedateError(true)}
                                                                        
                                    if((departureDate !== undefined && departureDate !== '')){
                                            loadFlights(departureDate,mobileNumber)
                                    }
                                }
                            }
                                                                                                   
                            onBack={() => {
                                onBack(previousPage())
                            }}
                            />
                    <br/>
                      {directFlight && <> <img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="DIRECT_FLIGHT_DATE encouragement text"/></b></>}
                      {affectedFlight && <> <img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="AFFECTED_FLIGHT_DATE encouragement text"/></b></>}
                      {lastLeg && <><img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="LAST_LEG_FLIGHT_DATE encouragement text"/></b></>}
                      {replacementFlight && <><img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="REPLACEMENT_FLIGHT_DATE encouragement text"/></b></>}
                        </div>
                    </div>
                </div>
    
            </>
        )
    }
    