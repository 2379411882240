import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl"
import { faCheck, faShareNodes } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import { useEffect } from "react";
import MyQRCodeComponent from "../shared/QrCode/MyQRCodeComponent";

export default function End({ compensate, status, CaseURL, isCaseRequest }) {

  const [openModal, setOpenModal] = useState(false)

  useEffect(()=>{
    if(openModal){
      const modalTimer =  setInterval(() => {
        setOpenModal(false)
      }, 5000);
      
      return () => {
      clearInterval(modalTimer);
    };
    }

    

  },[openModal])

  const handleShare = async () => {
    const currentUrl = window.location.href;
    const data = `${currentUrl}?r=${compensate.caseId}`;
    if(navigator.share){ 
      try {
        await navigator.share({
          title: 'Air Travel Claim',
          text: 'Here is the site I used to claim for my flight!',
          url: data,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else{
      navigator.clipboard.writeText(data)
      .then(() => {
        setOpenModal(true)
      })
      .catch((err) => {
        console.error('Error copying to clipboard:', err);
      });
    }
  };

  return (
    <>
      {openModal && <>
        <div className="firefox-modal">
          <FontAwesomeIcon icon={faCheck} className='pe-1'/><FormattedMessage id="URL copied to clipboard"/>
        </div>
        
      
      </>}
      <div className="heading uplers-icon fdc-form">
        {/*<img src={`/img/celebratory-image.svg`} alt="icon" style={{height:'275px', display: "block", margin: "auto", marginBottom: "10px"}}/>*/}
        <h2 className="title text-center"><FormattedMessage id="Thank you for submitting your Claim!" /></h2>
        
      </div>
      {compensate?.caseId ?
      <div className="fdc-form gif-input pt-0">
        <div className="box-message fw-semibold theme-color-1 text-center">
          <FormattedMessage id="Claim Reference :" /> {compensate?.caseId}
          <br />
          <br />
          <FormattedMessage id="We've sent you a confirmation email, if you haven't received it please check your 'Junk' or 'Spam' folders." />
          <img src="/img/Amazon_Voucher_Man.png" alt="Amazon Voucher" className="end-images"/>
          <br />
          <FormattedMessage id="You can refer a friend using this QR code!" />
          <br />
          <br />
          <h2 className="title"><b><FormattedMessage id="Share & Scan" /></b></h2>
          <MyQRCodeComponent compensate={compensate}/>
          <div className="py-2">
            <button 
              onClick={handleShare}
              style={{padding:'15px', backgroundColor: '#001A42', color: '#fff', borderRadius: '5px'}}>
              <div className="d-flex align-items-center">
              <b className="pe-2">SHARE </b>
              <FontAwesomeIcon icon={faShareNodes} size="2x" /></div>
            </button>
          </div>
          <FormattedMessage id="Every Scan of your QR code gets you £20"/>
          <br />
          <span style={{fontSize: '12px'}}><FormattedMessage id="When they successfully claim with us"/></span>
          <a 
            href="https://www.google.com/search?q=air+travel+claim&sca_esv=8ded6165f44582e6&rlz=1C1GCEB_enGB1103GB1103&sxsrf=ADLYWII2Vx11lgkR0s0XWCMmBPjaxZsOfw%3A1715956283912&ei=O2pHZtCjN_eqhbIPuvWbiAM&ved=0ahUKEwiQrYKm85SGAxV3VUEAHbr6BjEQ4dUDCBA&uact=5&oq=air+travel+claim&gs_lp=Egxnd3Mtd2l6LXNlcnAiEGFpciB0cmF2ZWwgY2xhaW0yBBAjGCcyBBAjGCcyChAjGIAEGCcYigUyDhAuGIAEGMcBGI4FGK8BMgUQABiABDIFEAAYgAQyBRAAGIAEMgYQABgWGB4yBhAAGBYYHjIGEAAYFhgeSOAiULIFWLwhcAJ4AZABAJgBuQGgAagEqgEDMC40uAEDyAEA-AEBmAIGoAK8BMICChAAGLADGNYEGEfCAgUQIRigAcICCxAAGIAEGIYDGIoFwgIIEAAYgAQYogSYAwCIBgGQBgeSBwMyLjSgB9Mh&sclient=gws-wiz-serp#lrd=0x48709ba162bd29b9:0x752d696fc81e88b1,3" 
            style={{backgroundColor: '#fff', border:'1px solid #001a42', borderRadius: '5px', display: 'flex', width: 'max-content', padding: '10px', margin : '18px auto 5px'}}
            target="_blank" rel="noreferrer">
            <div className="d-flex align-items-center">
              Review us on Google
              <div className="star-rating ps-1">
                <span>&#9733;</span>
                <span>&#9733;</span>
                <span>&#9733;</span>
                <span>&#9733;</span>
                <span>&#9733;</span>
              </div>
            </div>
          </a>
          <img src="/img/Review.png" alt="Leave Review" className="end-images"/>
        </div>
      </div>
      :<></>
}
       {/*<div className="fdc-form gif-input pt-0">
        <div className="box-message fw-semibold theme-color-1 text-center pt-0">
         <FormattedMessage id="Please look out for our Welcome email which you will receive within 24 hours." />
          <FormattedMessage id="You can now sit back and relax and we will do the rest. We will keep you informed on the status of your claim at each step of the way. Your airline may take 6-8 weeks to respond, however, our team will constantly be working on your claim for the best outcome."/>
          <br />
          <br />*

        </div>
      </div>/}

      {/* !process.env.REACT_APP_PRODUCTION && <div className="fdc-form gif-input">


        <br></br>

        {status === undefined ?
          <></>
          :
          <div>
            <span>Response:</span>
            <div className="box-message fw-semibold theme-color-1 text-center"
            >
              <h6> {status} </h6>
            </div>
          </div>
        }
      </div>
*/}
      

      {/* <br></br>

      <div className="fdc-form gif-input">
        <span>Request:</span>
        <div className="box-message fw-semibold theme-color-1 text-center wb" >
          <h6>{isCaseRequest}</h6>
        </div>
      </div> */}

    </>
  )
}