import { FormattedMessage } from "react-intl"
import Button_ from "../shared/Button";
import { useEffect } from "react";
import { COMPENSATION_SUBMIT_ENDPOINT, COMPENSATE_NOCLAIM_REASON } from "../../constants";
import axios from "axios";

export default function NoClaim({ compensate }) {

  useEffect(()=>{ 
    /* eslint-disable no-undef */
    if (typeof klaviyo !== 'undefined') {
      klaviyo.push(['track', 'No Claim', compensate?.flightData]);
    }
    /* eslint-enable no-undef */
    axios.post(COMPENSATION_SUBMIT_ENDPOINT, {
      web_form_id: "flight_delays_noclaim",
      flights: {
              [compensate?.connectingFlight ? "affectedFlight" : "directFlight"]: compensate?.flightData,
              lastLeg: compensate?.lastLeg,
              replacementFlight: compensate?.replacementFlight
            },
      tradingStyle: window.location.href.includes('airdelayed.com') 
      ? 'Air Delayed'
      : window.location.href.includes('flightclaims.co.uk')
        ? 'Flight Claims'
        : 'Air Travel Claim',
      email: compensate?.emailAddress
      
    }).then((res) => 
    {
      console.log(res.data)
    })
},[])

  
  const refreshPage = () => {
    window.location.reload(false);
  }

  return (
    <>
        <div className="heading uplers-icon fdc-form">
          <img src="/uplers-icons/icon4.svg" alt="icon" style={{display: "block", margin: "auto", marginBottom: "30px"}}/>
        <h2 className="title"><FormattedMessage id="We are sorry, you do not have a claim based on the information provided." /></h2>
      </div>

      <div className="fdc-form gif-input">
        <div className="box-message fw-semibold theme-color-1 text-center">
          <FormattedMessage id="We're sorry but we are unable to identify any available compensation for this flight. If you would like to try again, please click the button below and re-enter your flight details" />
          <br />
          <br />
          <div className="box-message fw-semibold theme-color-1 text-center">
            <h6> 
              <FormattedMessage id="No Claim Reason: "/>
              {COMPENSATE_NOCLAIM_REASON[compensate?.flightData?.decisionReason[0]] || 'Unknown'} </h6>
          </div>
          <Button_ 
            onClick={refreshPage}
            label="Try Again"
          />

        </div>
      </div>
    </>
  )
}
