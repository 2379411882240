import { useDispatch, useSelector } from 'react-redux';
import  useBackListener  from '../../hooks/useBackListener';
import End from '../../components/compensate-forms/End';
import CheckFlightDetails from '../../components/compensate-forms/CheckFlightDetails';
import NumberOfPassengers from '../../components/compensate-forms/NumberOfPassengers';
import {
  COMPENSATE_SCREEN,
  COMPENSATE_STEPPER_,
  COMPENSATION_SUBMIT_ENDPOINT,
  CRM_SUBMIT_ENDPOINT,
  CRM_UPDATE_ENDPOINT,
  CRM_UPDATE_STATE,
  SMS_SERVICE_URL,
  EMAIL_SERVICE_URL,
  COMPENSATE_OBJECT
} from '../../constants';
import PageLayout from '../../layouts/PageLayout';
import {
  setCompensateState,
  setCompensateStep
} from '../../store/actions/Compensate';
import './FlightCompensation.css';
import DirectFlightDate from '../../components/compensate-forms/DirectFlightDate';
import ManualFlightDetails from '../../components/compensate-forms/ManualFlightDetails';
import DelayReason1 from '../../components/compensate-forms/DelayReason1';
import DelayReason2 from '../../components/compensate-forms/DelayReason2';
import DelayReason from '../../components/compensate-forms/DelayReason';
import Passengers from '../../components/compensate-forms/Passengers';
import Address from '../../components/compensate-forms/Address';
import ContactDetails from '../../components/compensate-forms/ContactDetails';
import Signature from '../../components/compensate-forms/Signature';
import OtherComment from '../../components/compensate-forms/OtherComment';
import Evidence from '../../components/compensate-forms/Evidence';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { getCompensationPageView, getCompensationPayload, getCompensationPayloadForCRM, getCompensationUpdatePayloadForCRM } from './mapper';
import OthersPassengers from '../../components/compensate-forms/OthersPassengers';
import MobileValidation from '../../components/compensate-forms/MobileValidation';
import NoClaim from '../../components/compensate-forms/NoClaim';
import Error from '../../components/compensate-forms/Error';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faPhone, faEnvelope, faCheck, faRotate, faArrowUpFromBracket, faSignature, faPlane, faShareNodes, faUser, faUsers} from '@fortawesome/free-solid-svg-icons';
import ConnectingFlights from '../../components/compensate-forms/ConnectingFlights';
import DirectFlightSearch from '../../components/compensate-forms/DirectFlightSearch';
import AffectedFlightDate from '../../components/compensate-forms/AffectedFlightDate';
import AffectedFlightSearch from '../../components/compensate-forms/AffectedFlightSearch';
import LastLegFlightDate from '../../components/compensate-forms/LastLegFlightDate';
import LastLegFlightSearch from '../../components/compensate-forms/LastLegFlightSearch';
import ReplacmentFlightDate from '../../components/compensate-forms/ReplacmentFlightDate';
import ReplacementFlightSearch from '../../components/compensate-forms/ReplacementFlightSearch';
import { v4 as uuidv4 } from 'uuid';
import Summary from '../../components/compensate-forms/Summary';
//import Button_ from '../../components/shared/Button';
library.add(faCheckSquare, faPhone, faEnvelope, faCheck, faRotate, faArrowUpFromBracket, faSignature, faPlane, faShareNodes, faUser, faUsers)

export default function FlightCompensation() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [areDetailsSubmitted, setAreDetailsSubmitted] = useState(false)
  const [isCaseresponse, setCaseresponse] = useState('');
  const [isCaseURL, setCaseURL] = useState('');
  const [isCaseRequest, setCaseRequest] = useState('');

  //GUID function
  const [sessionId, setSessionId] = useState('');

  useEffect(() => {
    const storedSessionId = localStorage.getItem('sessionId');
    if (storedSessionId) {
      setSessionId(storedSessionId);
    } else {
      const newSessionId = uuidv4();
      localStorage.setItem('sessionId', newSessionId);
      setSessionId(newSessionId);
      
    }
    
  }, []);
   
  //variable for marketing channel URL parameter
  const marketingChannel =  new URL(window.location.href).searchParams.get('utm_source')|| new URL(window.location.href).searchParams.get('utm_medium')

  const dispatch = useDispatch();
  const { compensateStep, compensate } = useSelector(
    (state) => state.compensate
  );
  const onBack = (step) => {
    dispatch(setCompensateStep(step));
  };

  const onNext = (data, step) => {
    dispatch(setCompensateStep(step));
    dispatch(
      setCompensateState({
        ...compensate,
        ...data
      })
    );
  };
  console.log(compensateStep)

  //Back Button logic
  const handleBack = () => {
    console.log('Back button pressed');
    // Add your custom logic here
    if(compensateStep === 11 && !compensate.replacement && !compensate.lastLeg){
      onBack(compensateStep-6)
    }else if(compensateStep === 11 && !compensate.replacement && compensate.lastLeg){
      onBack(compensateStep-4)
    }else if(compensateStep === 5 || compensateStep === 6){
      onBack(compensateStep-3)
    }else if(compensateStep === 2 || compensateStep === 3){
      onBack(compensateStep-2)
    } else if(compensateStep > 0 && compensateStep < 10){
      onBack(compensateStep-1)
    } else if(compensateStep === 0 || compensateStep > 10){
      const url = new URL(window.location.href);
      url.pathname = `/${currentPage}/`;
      window.history.pushState({}, '', url.toString());
    }
  };
  useBackListener(handleBack);

  const onSubmit = (data, step) => {
    if (!compensate.caseId) {

      setIsSubmitted(true);
      const finalData = {
        ...compensate,
        ...data
      };
      dispatch(setCompensateState(finalData));

      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      const smsService = () =>{
        if(compensate.mobileNumber){
          const capitalizedFirstName = capitalizeFirstLetter(compensate.firstName);

          return axios.post(SMS_SERVICE_URL(),{
            payload:{
              'firstName': capitalizedFirstName,
              'mobile': compensate.mobileNumber,
              'tradingStyle': window.location.href.includes('airdelayed.com') ?
               'Air Delayed' : window.location.href.includes('flightclaims.co.uk') ?
               'Flight Claims' : 'Air Travel Claim'
            }
          })
        } else{
          return Promise.resolve()
        }
      }

      const emailService = () =>{
        if(compensate.emailAddress){
          const capitalizedFirstName = capitalizeFirstLetter(compensate.firstName);

          return axios.post(EMAIL_SERVICE_URL(),{
            payload:{
              'firstName': capitalizedFirstName,
              'email': compensate.emailAddress.trim(),
              'campaignId': window.location.href.includes('airdelayed.com') ? '191021' :
                window.location.href.includes('flightclaims.co.uk') ? '193708' :'191020',
              'domainIndex': window.location.href.includes('flightclaims.co.uk') ? 184 : null
            }
          }) 
               } else{
            return Promise.resolve()
          }
        }

      return Promise.allSettled([
        //mock entry
        axios.post(
          COMPENSATION_SUBMIT_ENDPOINT,
          getCompensationPayload(finalData)
        ),
        //CRM entry
        axios.post(CRM_SUBMIT_ENDPOINT(), getCompensationPayloadForCRM(finalData), {
          headers: {
            'Content-Type': 'application/json'
          }
        }),
        // 24/7 service
        smsService(),
        emailService()
      ])
        .then((e) => {

          
   console.log(e)
          if(e[1].value.data.message.error == true || e[1].value.data.message.error == false ){
          setCaseresponse(JSON.stringify(e[1].value.data.message))
          setCaseURL(e[1].value.data.URL)
          readJson(e[1].value.data.URL)
          }
          if (e[1].value.data.message.error !== false  || e[1].value.data.message.title == "Bad Request") {
            // setCaseresponse(JSON.stringify(e[1].value.data))
            // dispatch(setCompensateStep(COMPENSATE_SCREEN.ERROR));

            const errorData = {
              ...compensate,
              error: e[1].value.data.message.description ?? e[1].value.data.message.detail 
            }
            
            dispatch(setCompensateState(errorData));
            dispatch(setCompensateStep(COMPENSATE_SCREEN.ERROR));


          } else {
            const finalData = {
              ...compensate,
              caseId: e[1]?.value?.data?.message.case
            }
              dispatch(setCompensateState(finalData));
              if(marketingChannel === "Contact Centre"){
                dispatch(setCompensateStep(COMPENSATE_SCREEN.END))
                return axios.post(CRM_UPDATE_STATE(e[1]?.value?.data?.message.case),{
                payload:  {
                    state: "Claim Received Signature Requested"
                  }
                },
                { 
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }).then((e) =>{
                  console.log(e.data.message)
                })
              }else{
                dispatch(setCompensateStep(COMPENSATE_SCREEN.SIGNATURE + 1));
                const randomValue = Math.random()
                console.log(randomValue)
                if(compensate.hasBoardedReplacement === false || (!compensate?.lastLeg && !compensate?.replacementFlight && compensate.flightData.doNotProceedToLegalTrack !== false)
                  || (compensate?.replacementFlight && ( compensate?.replacementFlight?.doNotProceedToLegalTrack !== false || compensate?.replacementFlight?.validClaim === false))
                  || (compensate?.lastLeg && !compensate?.replacementFlight && ( compensate.lastLeg.doNotProceedToLegalTrack !== false || compensate?.lastLeg?.validClaim === false))){
                  return axios.post(CRM_UPDATE_STATE(e[1]?.value?.data?.message.case),{
                    payload:  {
                      state: "Claim Received Review Required"
                    }
                  },
                  { 
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }).then((e) =>{
                    console.log(e.data.message)
                  })                  
                }else if(compensate.flightData?.operatingAirlineName === 'easyJet'){
                  return axios.post(CRM_UPDATE_STATE(e[1]?.value?.data?.message.case),{
                    payload:  {
                      state: "Airline Letter of Claim Cooling Off Period"
                    }
                  },
                  { 
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }).then((e) =>{
                    console.log(e.data.message)
                  })
                }else if(randomValue >= 0.9){
                  return axios.post(CRM_UPDATE_STATE(e[1]?.value?.data?.message.case),{
                    payload:  {
                      state: "Claim Received Review Required"
                    }
                  },
                  { 
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }).then((e) =>{
                    console.log(e.data.message)
                  })                  
                }else{
                  return axios.post(CRM_UPDATE_STATE(e[1]?.value?.data?.message.case),{
                    payload:  {
                      state: "Letter Of Claim Ready to Send"
                    }
                  },
                  { 
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }).then((e) =>{
                    console.log(e.data.message)
                  })
                } 
              }
            }
              return e[1].value.data;
            })
            .finally(() => {
              setIsSubmitted(false);
            });
    } else {
      dispatch(setCompensateStep(step));
      console.log(compensate.caseId)
      return Promise.resolve({ caseId: compensate.caseId });
    }
  };

  const onUpdate = async (data, step) => {
    setIsSubmitted(true);
    const finalData = {
      ...compensate,
      ...data
    };
    dispatch(setCompensateState(finalData));

    return axios.post(CRM_UPDATE_ENDPOINT(compensate.caseId), getCompensationUpdatePayloadForCRM(data), {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((e) => {

      if (e.data.error == true) {
        setCaseresponse(JSON.stringify(e[1].value.data))
        // dispatch(setCompensateStep(COMPENSATE_SCREEN.ERROR));
        dispatch(setCompensateStep(step));
      } else {
        dispatch(setCompensateStep(step));
      }
    })
      .finally(() => {
        dispatch(setCompensateStep(step));
        setIsSubmitted(false);
      });
  };

  async function readJson(file) {
    const response = await fetch(file);
    const jsonData = await response.json();
    setCaseRequest(JSON.stringify(jsonData))

  }
  const renderStepForm = () => {
    setTimeout(() => {

      window.scroll({ top: -1, left: 0, behavior: "smooth" });
  
  }, 10); 
 
    switch (compensateStep) {
     // case COMPENSATE_SCREEN.NUMBER_OF_PASSENGERS:
      //  return <NumberOfPassengers onNext={onNext} compensate={compensate} />;
      case COMPENSATE_SCREEN.START_DESTINATION:
        return (
          <CheckFlightDetails
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
          />
        );
      case COMPENSATE_SCREEN.CONNECTING_FLIGHTS:
        return(
          <ConnectingFlights
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
          />
        )
      case COMPENSATE_SCREEN.DIRECT_FLIGHT_DATE:
        return (
          <DirectFlightDate
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
            isSubmitted={setIsSubmitted}
            areDetailsSubmitted={areDetailsSubmitted}
            setAreDetailsSubmitted={setAreDetailsSubmitted}
          />
        );
      case COMPENSATE_SCREEN.AFFECTED_FLIGHT_DATE:
        return <AffectedFlightDate 
          onBack={onBack}
          onNext={onNext}
          compensate={compensate}
          isSubmitted={setIsSubmitted}
          areDetailsSubmitted={areDetailsSubmitted}
          setAreDetailsSubmitted={setAreDetailsSubmitted}
        />
      case COMPENSATE_SCREEN.MOBILE_VALIDATION:
        return (
          <MobileValidation
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
          />
        );
      case COMPENSATE_SCREEN.DIRECT_FLIGHT_SEARCH:
        return (
          <DirectFlightSearch
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
            isSubmitted={setIsSubmitted} 
          />
        );
      case COMPENSATE_SCREEN.AFFECTED_FLIGHT_SEARCH:
        return (
          <AffectedFlightSearch
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
            isSubmitted={setIsSubmitted} 
          />
        );
      case COMPENSATE_SCREEN.LAST_LEG_FLIGHT_DATE:
        return (
          <LastLegFlightDate 
          onBack={onBack}
          onNext={onNext}
          compensate={compensate} 
          isSubmitted={setIsSubmitted}
          areDetailsSubmitted={areDetailsSubmitted}
          setAreDetailsSubmitted={setAreDetailsSubmitted} 
        />
        )
      case COMPENSATE_SCREEN.LAST_LEG_FLIGHT_SEARCH:
        return (
          <LastLegFlightSearch 
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
            isSubmitted={setIsSubmitted} 
          />
          );
      case COMPENSATE_SCREEN.REPLACEMENT_FLIGHT_DATE:
        return( 
          <ReplacmentFlightDate 
            onBack={onBack} 
            onNext={onNext} 
            compensate={compensate}
            isSubmitted={setIsSubmitted}
            areDetailsSubmitted={areDetailsSubmitted}
            setAreDetailsSubmitted={setAreDetailsSubmitted}
          />
          );
      case COMPENSATE_SCREEN.REPLACEMENT_FLIGHT_SEARCH:
        return( 
          <ReplacementFlightSearch 
            onBack={onBack} 
            onNext={onNext} 
            compensate={compensate}
            isSubmitted={setIsSubmitted} 
          />
          );
        case COMPENSATE_SCREEN.DELAY_REASON:
        return (
          <DelayReason
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
          />
        );
      case COMPENSATE_SCREEN.PASSENGERS:
        return (
          <Passengers onBack={onBack} onNext={onNext} compensate={compensate} />
        );
      case COMPENSATE_SCREEN.ADDRESS:
        return (
          <Address onBack={onBack} onNext={onNext} compensate={compensate} />
        );
      case COMPENSATE_SCREEN.OTHER_PASSENGERS:
        return (
          <OthersPassengers
            onBack={onBack}
            onNext={onNext}
            compensate={compensate}
            submitAction={onSubmit}
          />
        );
      case COMPENSATE_SCREEN.SIGNATURE:
        return (
          <Signature onBack={onBack} onNext={onNext} compensate={compensate} submitAction={onSubmit} updateAction={onUpdate} isSubmitted={isSubmitted} />
        );
      case COMPENSATE_SCREEN.EVIDENCE:
          return (
            <Evidence onBack={onBack} onNext={onNext} compensate={compensate} updateAction={onUpdate} isSubmitted={isSubmitted}/>
        );
      case COMPENSATE_SCREEN.SUMMARY:
        return(
          <Summary onBack={onBack} onNext={onNext} compensate={compensate}/>
        )
      case COMPENSATE_SCREEN.OTHER_COMMENTS:
        return (
          <OtherComment
            onBack={onBack}
            isSubmitted={isSubmitted}
            onSubmit={onSubmit}
            compensate={compensate}
            updateAction={onUpdate}
          />
        );
      case COMPENSATE_SCREEN.END:
        //return <></>;
        return <End
          compensate={compensate}
          status={isCaseresponse}
          CaseURL={isCaseURL}
          isCaseRequest={isCaseRequest}
        />;
      case COMPENSATE_SCREEN.No_CLAIM:
        return <NoClaim
          compensate={compensate}
        />;
      case COMPENSATE_SCREEN.ERROR:
        return <Error
          compensate={compensate}
        />;
      default:
        return <></>;
    }
  };
  //track page by variable
  const keysArray = Object.keys(COMPENSATE_SCREEN)
  const currentPage = keysArray[compensateStep];


  useEffect(() => {
    const url = new URL(window.location.href);
    url.pathname = `/${currentPage}/`;
    window.history.pushState({}, '', url.toString());

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      page_location: window.location.href,
      page_title: currentPage
    });
    
    /* eslint-disable no-undef */
    
    function gtag(){window.dataLayer.push(arguments)}
    if ( currentPage === 'EVIDENCE' && window.location.href.includes('airdelayed.com')){
      gtag('event', 'conversion', {'send_to': 'AW-11440999755/sAfcCM7c-_8YEMuav88q'});
      console.log('air delayed script ran')
      window.fbq('track', 'Lead');
    }
    if(currentPage === 'EVIDENCE' && window.location.search.includes('p=fc')){
      gtag('event', 'conversion', {'send_to': 'AW-721172035/OQtZCITUipoZEMPs8NcC'});
      gtag('event', 'conversion', {'send_to': 'AW-11438833728/zUQ4CMLJz_4YEMCAu84q'});
    }

    if ( currentPage === 'EVIDENCE' && window.location.href.includes('flightclaims.co.uk')){
      gtag('event', 'conversion', {'send_to': 'AW-721172035/OQtZCITUipoZEMPs8NcC'});
      gtag('event', 'conversion', {'send_to': 'AW-11438833728/zUQ4CMLJz_4YEMCAu84q'});
    }
    
    if (typeof klaviyo !== 'undefined') {
      if (compensate.emailAddress) {
        klaviyo.push(['identify', { 'email': compensate.emailAddress }]);
      }
      klaviyo.push(['track', 'Viewed Page', { url: window.location.href, pageName: currentPage }]);
    } else {
      // Klaviyo script not loaded, log an error or handle accordingly
      console.error('Klaviyo script not loaded.');
    }
    /* eslint-enable no-undef */
    
    const pageNumber = COMPENSATE_OBJECT[currentPage].pageNumber || 'UNKNOWN';
    const pageView = pageNumber +' '+ currentPage
    console.log(pageView)

    if(sessionId){
      axios.post(COMPENSATION_SUBMIT_ENDPOINT, getCompensationPageView(compensate, pageView, currentPage, sessionId))
      dispatch(setCompensateState({
          ...compensate,
          sessionId
        })
      )
    }

  }, [currentPage, sessionId])


  /*const renderStepper = () => {
    const usedLabel = [];
    const praparedSteppers = [];
    Object.keys(COMPENSATE_STEPPER_).map(key => {

      if (!usedLabel.includes(COMPENSATE_STEPPER_[key].label)) {
        usedLabel.push(COMPENSATE_STEPPER_[key].label);

        praparedSteppers.push(
          <div className={`stepper-block ${COMPENSATE_SCREEN[key] <= compensateStep ? 'active' : ''}`}>
            <div className="stepper-loader">
              <div style={{ width: `${(100 / COMPENSATE_SCREEN[key]) * (1)}%`}}></div>
            </div>
          </div>
        )
      }

    })
    return praparedSteppers;
  }*/
  //Forms Progress Bar
  const renderProgressBar = () =>{
    const formTitlesLength = (Object.keys(COMPENSATE_SCREEN).length - 3)
    const progressPercentage = COMPENSATE_OBJECT[currentPage].pageProgress;
    return  <div className="stepper-loader align-items-center" style={{display: compensateStep > formTitlesLength -1 ? 'none' : 'flex'}}>
              <div style={{ width: progressPercentage }}></div>
              <img style={{height: '60px', marginLeft: '-35px', marginRight: '-10px'}}src="/uplers-icons/progress-bar.svg" alt="progress bar icon" />
            </div>
  }
  
/*const screenShot = () =>{
  axios.get("http://localhost:4000/screenshot/",{
    responseType:'blob'
  }).then((res) =>{
    const imageUrl = URL.createObjectURL(res.data);

    // Create an <img> element
    const imgElement = document.createElement('img');
    imgElement.src = imageUrl;
    console.log(imageUrl)
  })
}*/
  return (<>
    {/*currentPage === 'START_DESTINATION' && <div className=" py-3" style={{background: '#eb534c', color: 'white'}}>
          <div className="container text-center">Important Notice: Air Traffic Control issues are considered 'Extraordinary Circumstances'. Unfortunately, they're not eligible for claims.</div>
      </div>*/}
    <PageLayout>
    <div className={currentPage}></div>
      <div className="container">
        <div className="fdc-form-wrapp">
          
          <div className="fdc-stepper-wrapp">
            {renderProgressBar()}
          </div>


          <div className={`fdc-shadow-block ${isSubmitted ? 'active-spinner' : ''}`}>
            <div className="loading-overlay"></div>
            <div className="loader"></div>
            
            {renderStepForm()}
          </div>
        {/*<Button_
          label="Screenshot"
  onClick={screenShot}
        />*/}
        </div>
      </div>

      {/* <div className="circle md orange left center"></div>
      <div className="circle md blue right"></div> */}

    </PageLayout>
  </>);
}
