import React from 'react'
import FlightList from '../shared/FlightList/FlightList'
import { COMPENSATE_SCREEN } from '../../constants'

export default function ReplacementFlightSearch({ compensate, onNext, onBack, isSubmitted }) {

  const nextPage = (validClaim) =>{
    return validClaim || compensate.flightData.validClaim ? COMPENSATE_SCREEN.DELAY_REASON : COMPENSATE_SCREEN.No_CLAIM
  }

  const previousPage = () =>{
    return COMPENSATE_SCREEN.REPLACEMENT_FLIGHT_DATE
  }

  return (
    <FlightList 
      onBack={onBack}
      onNext={onNext}
      compensate={compensate} 
      nextPage={nextPage}
      previousPage={previousPage}
      departure={compensate?.connectingFlights[compensate.stops.length]?.departureAirport }
      destination={compensate?.connectingFlights[compensate.stops.length]?.destinationAirport}
      replacementFlight
      isSubmitted={isSubmitted}
    />
  )
}
