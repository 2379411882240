import axios from "axios";
import { useEffect } from "react";
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, GET_CONNECTING_FLIGHT_ENDPOINT } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import ListRadioOptions from "../shared/ListRadioOptions";
import Text from "../shared/Text";

export default function DelayReason({ compensate, onNext, onBack }) {
    const [delayReason3, setDelayReason3] = useState(compensate.delayReason3)
    const [flightClaimReasonOther, setFlightClaimReasonOther] = useState(compensate.flightClaimReasonOther)
    const [howManyHoursLate, setHowManyHoursLate] = useState(compensate.howManyHoursLate)
    const [causedTheDelay, setCausedTheDelay] = useState(compensate.causedTheDelay)
    const [airlineReasonForDelayOther, setAirlineReasonForDelayOther] = useState(compensate.airlineReasonForDelayOther)

    const [whenInformedFlightCancelled, setWhenInformedFlightCancelled] = useState(compensate.whenInformedFlightCancelled)
    const [wereOfferedAlternativeFlight, setWereOfferedAlternativeFlight] = useState(compensate.wereOfferedAlternativeFlight)
    const [alternativeFlightNumber, setAlternativeFlightNumber] = useState(compensate.alternativeFlightNumber)
    const [checkInForBoarding, setCheckInForBoarding] = useState(compensate.checkInForBoarding)

    const [flightClaimOtherError, setflightClaimOtherError] = useState(false)
    const [howManyHoursLateError, sethowManyHoursLateError] = useState(false)
    const [causedTheDelayError, setcausedTheDelayError] = useState(false)
    const [whenInformedFlightCancelledError, setwhenInformedFlightCancelledError] = useState(false)
    const [wereOfferedAlternativeFlightError, setwereOfferedAlternativeFlightError] = useState(false)
    const [alternativeFlightNumberCancelledError, setalternativeFlightNumberCancelledError] = useState(false)
    const [checkInForBoardingError, setcheckInForBoardingError] = useState(false)
    const [flightData, setFlightData] = useState(compensate.flightData ?? {})


    const intl = useIntl();

    console.log(compensate.flightData)

    

    const delayReasons = [
        { text: intl.formatMessage({id:"My flight was delayed."}), value: "Delayed" },
        { text: intl.formatMessage({id:"My flight was cancelled"}), value: "Cancelled" },
        { text: intl.formatMessage({id:"I was denied boarding"}), value: "Denied Boarding" },
        // { text: "My flight was rerouted", value: "Rerouted" },
        // { text: "My flight was returned", value: "Returned" },
        //{ text: intl.formatMessage({id:"Other"}), value: "Other" },
    ];

    const howManyHourLatesOptions = [
        // { text: "0 - 1 Hours", value: "0-1 Hours" },
        // { text: "1 - 2 Hours", value: "1-2 Hours" },
        // { text: "2 - 3 Hours", value: "2-3 Hours" },
        // { text: "3 - 4 Hours", value: "3-4 Hours" },
        // { text: "4+ Hours", value: "4+ Hours" },
        { text: intl.formatMessage({id:"Under 3 Hours"}), value: "Under 3 Hours" },
        { text: intl.formatMessage({id:"3 Hours or More"}), value: "3 Hours or More" },
        //{ text: "Never Arrived", value: "Never Arrived" },
    ];


    const causedTheDelayOptions = [
        { text: intl.formatMessage({id:"Technical Fault"}), value: "Technical Fault" },
        { text: intl.formatMessage({id:"Airline staff on strike"}), value: "Airline staff on strike" },
        { text: intl.formatMessage({id:"Bad Weather"}), value: "Bad Weather" },
        { text: intl.formatMessage({id:"Problems at airport"}), value: "Problems at Airport" },
        // { text: "Extraordinary Circumstances", value: "Extraordinary Circumstances" },
        // { text: "Industrial Action", value: "Industrial Action" },
      //  { text: intl.formatMessage({id:"Don't know"}), value: "Don't Know" },
       // { text: intl.formatMessage({id:"Other"}), value: "Other" },
    ];

    const whenInformedFlightCancelledOptions = [
        { text: intl.formatMessage({id:"Less than 7 days before departure"},{7: <b>7</b>}), value: "0-6 Days" }, 
        { text: intl.formatMessage({id:"7 to 13 days before departure."},{7: <b>7</b>, 13: <b>13</b>}), value: "7-13 Days" },
        { text: intl.formatMessage({id:"More than 14 days before departure."},{14: <b>14</b> }), value: "14+ days" },
    ];

    const causedTheDelayOptionsForDenied = [
        { text: intl.formatMessage({id:"Flight Overbooked"}), value: "Flight Overbooked" },
        { text: intl.formatMessage({id:"Intoxication or under the influence of drugs"}), value: "Intoxication or under the influence of drugs" },
        { text: intl.formatMessage({id:"Unruly or disruptive behaviour"}), value: "Unruly or disruptive behaviour" },
        { text: intl.formatMessage({id:"Volunteered to take a later flight"}), value: "Volunteered to take a later flight" },
    ];

    const boardingDeniedOptions = [
        { text: "Flight overbooked", value: "Flight Overbooked" },
        { text: "Intoxication or under the infuence of drugs", value: "Intoxication or under the influence of drugs" },
        { text: "Unruly or disruptive behaviour", value: "Unruly or disruptive behavior" },
        { text: "I volunteered to take a later flight", value: "Volunteered to take a later flight" },
    ];

    const nextScreen = () => {
            onNext({
                delayReason3,
                flightClaimReasonOther,
                howManyHoursLate,
                causedTheDelay,
                whenInformedFlightCancelled,
                wereOfferedAlternativeFlight,
                alternativeFlightNumber,
                checkInForBoarding,
                airlineReasonForDelayOther,
                flightData
            }, ( whenInformedFlightCancelled === '14+ days'                 
                    ? COMPENSATE_SCREEN.No_CLAIM 
                    : COMPENSATE_SCREEN.DELAY_REASON + 1))
       
    }

    /*const flightDelayed = () => {
        return (
            <>
                <div className="col-md-12">
                    <div className="form-block">
                        <ListRadioOptions
                            label={intl.formatMessage({ id: "How many hours late did you arrive at your final destination?" })}
                            items={howManyHourLatesOptions}
                            onSelect={setHowManyHoursLate}
                            value={howManyHoursLate}
                        />

                           {howManyHoursLateError && howManyHoursLate == undefined?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please select the number of hours delayed"/>
                                    </span>
                                </div>
                                :<></>
                              }


                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-block">
                        <ListRadioOptions
                            label={intl.formatMessage({ id: "What did the airline say caused the delay?" })}
                            items={causedTheDelayOptions}
                            onSelect={setCausedTheDelay}
                            value={causedTheDelay}
                        />
                        {causedTheDelayError && causedTheDelay === undefined?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please Select Options Airline Cause Delay"/>
                                    </span>
                                </div>
                                :<></>
                              }
                    </div>
                </div>
                {
                    causedTheDelay === 'Other' && <>
                        <div className="col-md-12">
                            <div className="form-block">
                                <Text
                                    onChange={(e) => setAirlineReasonForDelayOther(e.target.value)}
                                    value={airlineReasonForDelayOther}
                                />
                            
                             {flightClaimOtherError && airlineReasonForDelayOther === undefined ?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please Enter the Reason for Airline Delay"/>
                                    </span>
                                </div>
                                :<></>
                              }

                            </div>
                        </div>
                    </>
                }
            </>
        )
    }*/

    const flightCancelled = () => {
        return (
            <>
                <div className="col-md-12">
                    <div className="form-block">
                        <ListRadioOptions
                            label={intl.formatMessage({ id: "When were you informed the flight was cancelled?" })}
                            items={whenInformedFlightCancelledOptions}
                            onSelect={setWhenInformedFlightCancelled}
                            value={whenInformedFlightCancelled}
                        />

 
                            {whenInformedFlightCancelledError && whenInformedFlightCancelled  === undefined ?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please select an option"/>
                                    </span>
                                </div>
                                :<></>
                              }
                    </div>
                </div>
                 {/*<div className="col-md-12">
                    <div className="form-block">
                        <ListRadioOptions
                            label={intl.formatMessage({ id: "Were you offered an alternative flight that you accepted?" })}
                            items={[{ text: intl.formatMessage({ id:"Yes"}), value: "Yes" }, { text: intl.formatMessage({ id:"No"}), value: "No" }]}
                            onSelect={(val) => {
                                if (val === "No") {
                                    setAlternativeFlightNumber("");
                                }
                                setWereOfferedAlternativeFlight(val)
                            }}
                            value={wereOfferedAlternativeFlight}
                        />
                    </div>
                </div>
                 
                        wereOfferedAlternativeFlight === "Yes" &&
                <div className="col-md-12">
                    <div className="form-block">
                            <Text
                                label={intl.formatMessage({ id: "What was the alternative flight number?" })}
                                onChange={(e) => setAlternativeFlightNumber(e.target.value)}
                                value={alternativeFlightNumber}
                                placeholder={intl.formatMessage({ id: "Flight Number" })}
                                required={false}
                            />
                    </div>
                </div>
                    
                <div className="col-md-12">
                    <div className="form-block">
                        <ListRadioOptions
                            label={intl.formatMessage({ id: "How many hours late did you arrive at your final destination?" })}
                            items={howManyHourLatesOptions}
                            onSelect={setHowManyHoursLate}
                            value={howManyHoursLate}
                        />
                    </div>
                </div> 
                <div className="col-md-12">
                    <div className="form-block">
                        <ListRadioOptions
                            label={intl.formatMessage({ id: "What did the airline say caused the cancellation" })}
                            items={causedTheDelayOptions}
                            onSelect={setCausedTheDelay}
                            value={causedTheDelay}
                        />

                         {causedTheDelayError && causedTheDelay === undefined?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please select an option"/>
                                    </span>
                                </div>
                                :<></>
                              }

                    </div>
                </div>
                */}
                {
                    causedTheDelay === 'Other' && <>
                        <div className="col-md-12">
                            <div className="form-block">
                                <Text
                                    onChange={(e) => setAirlineReasonForDelayOther(e.target.value)}
                                    value={airlineReasonForDelayOther}
                                />

                          {flightClaimOtherError && airlineReasonForDelayOther === undefined ?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please enter the reason for flight cancellation"/>
                                    </span>
                                </div>
                                :<></>
                              }
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }

    {/*const flightDenied = () => {
        return (
            <>
                <div className="col-md-12">
                    <div className="form-block">
                        <ListRadioOptions
                            label={intl.formatMessage({ id: "Did you check in and present yourself for boarding?" })}
                            items={[{ text: intl.formatMessage({ id:"Yes"}), value: "Yes" }, { text: intl.formatMessage({ id:"No"}), value: "No" }]}
                            onSelect={setCheckInForBoarding}
                            value={checkInForBoarding}
                            
                        />

                           {checkInForBoardingError && checkInForBoarding  === undefined ?
                                 <div>
                                    <span className="text-danger">
                                    <FormattedMessage id="Please select an option"/>
                                    </span>
                                </div>
                                :<></>
                              }
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-block">
                        <ListRadioOptions
                            label={intl.formatMessage({ id: "What reason were you given for being denied boarding?" })}
                            items={causedTheDelayOptionsForDenied}
                            onSelect={setCausedTheDelay}
                            value={causedTheDelay}
                            className={'d-inline-flex d-sm-block'}
                        />
                
                          {causedTheDelayError && causedTheDelay  === undefined ?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please select an option"/>
                                    </span>
                                </div>
                                :<></>
                              }
                    </div>
                </div>
                {
                    causedTheDelay === 'Other' && <>
                        <div className="col-md-12">
                            <div className="form-block d-inline-flex">
                                <Text
                                    onChange={(e) => setAirlineReasonForDelayOther(e.target.value)}
                                    value={airlineReasonForDelayOther}

                                />
                               {flightClaimOtherError && airlineReasonForDelayOther === undefined ?
                                 <div>
                                    <span className="text-danger">
                                    <FormattedMessage id="Please enter the reason why you were denied boarding"/>
                                    </span>
                                </div>
                                :<></>
                              }


                            </div>
                        </div>
                    </>
                }
                 <div className="col-md-12">
                    <div className="form-block">
                        <ListRadioOptions
                            label={intl.formatMessage({ id: "How many hours late did you arrive at your final destination?" })}
                            items={howManyHourLatesOptions}
                            onSelect={setHowManyHoursLate}
                            value={howManyHoursLate}
                        />
                    </div>
                </div> 
            </>
        )
    }*/}

    const otherReason = () => (
        <>
            <div className="col-md-12">
                <div className="form-block">
                    <Text
                        onChange={(e) => setFlightClaimReasonOther(e.target.value)}
                        value={flightClaimReasonOther}
                    />
                      {flightClaimOtherError && flightClaimReasonOther === undefined ?
                       <div>
                            <span className="text-danger">
                                <FormattedMessage id="Please enter the issue with your flight"/>
                             </span>
                            </div>
                      :<></>
                     }
                </div>
            </div>
        </>
    )

    const renderReasonQuestionBlock = () => {
        if (!delayReason3) return <></>;
        switch (delayReason3) {
            case delayReasons[1].value:
                return flightCancelled();
        }
    }

    return (
        <>
            <div className="heading fdc-form d-flex justify-content-center align-items-center uplers-icon">
                <img src="/uplers-icons/icon2.svg" /> 
                <h2 className="title"> <FormattedMessage id="Let's check your flight details" /></h2>
            </div>
            <div className="fdc-form gif-input">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-block">
                            <ListRadioOptions
                                label={intl.formatMessage({ id: "What was the issue with your flight?" })}
                                items={delayReasons}
                                onSelect={setDelayReason3}
                                value={delayReason3}
                                className={`d-inline-flex d-sm-block`}
                            />
                        </div>
                    </div>

                    {
                        renderReasonQuestionBlock()
                    }
                    <div className="col-md-6 text-center mx-auto">

                        <FormActionButton
                            onNext={() => {
                                if(delayReason3 === 'Cancelled'){
                                    whenInformedFlightCancelled === undefined ||  whenInformedFlightCancelled === '' ? setwhenInformedFlightCancelledError(true) : setwhenInformedFlightCancelledError(false) 
                                    causedTheDelay === undefined ||  causedTheDelay === '' ? setcausedTheDelayError(true) : setcausedTheDelayError(false) 
                                    if((causedTheDelay === 'Other' && airlineReasonForDelayOther === '') || (causedTheDelay === 'Other' && airlineReasonForDelayOther === undefined)) {{  setflightClaimOtherError(true) } }
                                     
                                    if(whenInformedFlightCancelled !== undefined &&  whenInformedFlightCancelled !== ''){
                                       nextScreen() 
                                     }
                                    } else {
                                        nextScreen()
                                    }


                            }}
                            onBack={() => {
                                if(compensate?.isMissedConnection){
                                    onBack(COMPENSATE_SCREEN.REPLACEMENT_FLIGHT_SEARCH)
                                }else if(compensate?.connectingFlight && compensate?.affectedFlightss !== `Flight ${compensate?.connectingFlights?.length}`) {
                                    onBack(COMPENSATE_SCREEN.LAST_LEG_FLIGHT_SEARCH)
                                }else if(compensate?.connectingFlight){
                                    onBack(COMPENSATE_SCREEN.AFFECTED_FLIGHT_SEARCH)
                                }else{
                                    onBack(COMPENSATE_SCREEN.DIRECT_FLIGHT_SEARCH)
                            }
                        }}
                        />
                        <br/>
                        <img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="DELAY_REASON encouragement text"/></b>
                    </div>
                </div>
            </div>

        </>
    )
}