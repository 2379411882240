import React, { useEffect } from 'react';
import QRCode from 'qrcode';

const MyQRCodeComponent = ({compensate}) => {
  useEffect(() => {
    const generateQRCode = async () => {
      try {
        const currentUrl = window.location.href;
        const data = `${currentUrl}?r=${compensate.caseId}`;
        const options = {
          color: {
            dark: '#000',
            light: '#fff'
          }
        };

        const canvas = await QRCode.toCanvas(data, options);
        document.getElementById('qrcode-container').appendChild(canvas);
      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    };

    generateQRCode();
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <div id="qrcode-container">
      {/* The generated QR code will be appended here */}
    </div>
  );
};

export default MyQRCodeComponent;
